<template>
    <div>
        <vue-title title="Подтверждение пользователя | KEL.KZ"></vue-title>
        <Header :routes="headerRoutes.defaultsRoutes"/>
        <div class="container py-4 mb-2">
            <div class="long-title text-center mb-3">
                <h1 class="page-title">Подтверждение пользователя</h1>
            </div>
            <div class="row">
                <div class="col-12 col-md-2 col-lg-3"></div>
                <div class="col-12 col-md-8 col-lg-6">
                    <div id="form-container">
                        <div v-if="okMessage" class="alert alert-success mb-3" role="alert">
                            {{ okMessage }}
                        </div>
                        <div v-if="errorMessage" class="alert alert-danger mb-3" role="alert">
                            <strong>Внимание!</strong> {{ errorMessage }}
                        </div>
                        <form class="verified-form" @submit.prevent="onSubmit">
                            <DxForm
                                    id="form"
                                    :col-count="1"
                                    :form-data="formData"
                                    :read-only="false"
                                    :show-colon-after-label="true"
                                    :show-validation-summary="true"
                                    :label-location="labelLocation"
                                    :disabled="verified"
                            >
                                <DxSimpleItem
                                        data-field="pinCode"
                                        :editor-options="{maxLength: 4, minLength: 4}"
                                >
                                    <DxLabel text="Введите PIN код для подтверждения"/>
                                    <DxRequiredRule message="Укажите PIN код"/>
                                    <DxStringLengthRule
                                            :min="4"
                                            :max="4"
                                            message="PIN код должен содержать 4 символа"
                                            type="stringLength"
                                    />
                                </DxSimpleItem>
                                <DxGroupItem :colCount="8" :alignItemLabels="true">
                                    <DxColCountByScreen :xs="2" :sm="2"/>
                                    <DxButtonItem
                                            horizontalAlignment="right"
                                            cssClass="pt-3"
                                            :button-options="buttonCancelOptions"
                                            :colSpan="6"
                                    />
                                    <DxButtonItem
                                            cssClass="pt-3"
                                            :button-options="buttonVerifiedOptions"
                                            :colSpan="2"
                                    />
                                </DxGroupItem>
                            </DxForm>
                        </form>
                    </div>
                </div>
                <div class="col-12 col-md-2 col-lg-3"></div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
    import headerRoutes from '../header-routes';
    import {mapGetters} from 'vuex';
    import {httpClient} from '../../../shared/services';
    import {Header, Footer} from '../../../shared/components';
    import {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxRequiredRule,
        DxButtonItem,
        DxStringLengthRule,
        DxGroupItem,
        DxColCountByScreen
    } from 'devextreme-vue/form';

    export default {
        name: 'Verified',
        components: {
            Header,
            Footer,
            DxForm,
            DxSimpleItem,
            DxLabel,
            DxRequiredRule,
            DxButtonItem,
            DxStringLengthRule,
            DxGroupItem,
            DxColCountByScreen
        },
        data() {
            return {
                headerRoutes,
                formData: {},
                labelLocation: 'top',
                verified: false,
                errorMessage: "",
                okMessage: "",
                buttonVerifiedOptions: {
                    text: 'Подтвердить',
                    type: 'default',
                    useSubmitBehavior: true
                },
                buttonCancelOptions: {
                    text: 'Отмена',
                    type: 'normal',
                    useSubmitBehavior: false,
                    onClick: this.cancelVerified
                },
            }
        },
        mounted() {

        },
        methods: {
            onSubmit: async function () {
                this.errorMessage = "";
                this.okMessage = "";
                this.verified = true;

                const {pinCode} = this.formData;

                httpClient.post(`v2/Agent/${this.Agent.tokenId}/Verified/${pinCode}`).then((response) => {
                    //console.log(response.data);
                    this.$store.dispatch('logIn', response.data).then(() => {
                        this.$store.dispatch('loading', false);
                        this.$router.push(this.$route.query.redirect || '/');
                    });
                }).catch(this.handleError);
            },
            handleError: function (error) {
                if(error.response.status === 404 || error.response.status === 400) {
                    if(error.response.data) {
                        this.errorMessage = error.response.data;
                    } else {
                        this.errorMessage = 'Неверно указан PIN код. Проверьте введенные данные и попробуйте еще раз.';
                    }
                } else {
                    this.errorMessage = 'Неверно указан PIN код. Проверьте введенные данные и попробуйте еще раз.';
                }

                this.verified = false;
                console.error(error.response);
            },
            cancelVerified() {
                this.verified = true;
                this.$store.dispatch('logOut', this.Agent);
            }
        },
        computed: {
            ...mapGetters([
                'isLoading', 'Agent'
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
